




































import {Component, Mixins, Prop, Watch} from 'vue-property-decorator';
import DynamicForm from '@/components/Forms/DynamicForm.vue';
import AbSubRecordModel from '@/components/Models/AbSubRecordModel';
import DynamicSection from '@/components/Forms/DynamicSection.vue';
import store from "@/store";
import FormMixin from '@/components/Mixin/FormMixin';

@Component({
  components: {DynamicSection}
})
export default class DynamicSubRecord extends Mixins(FormMixin, DynamicForm) {
  @Prop(Object) subFormFieldData;
  @Prop(Object) subFormFieldInfo;
  @Prop(Object) saveMainFormBefore;

  model: AbSubRecordModel = new AbSubRecordModel();

  async saveToModel(dataToSave, ID = null) {
    if (!this.parentFormData.data.ID && !ID) {
      // save main form before
      this.parentFormData.callbackAfterSave = (ID) => { this.saveToModel(dataToSave, ID) };
      this.$emit('saveTheForm', true);
    } else {
      this.model.setMainData({
        documentID: this.parentFormData.data.ID || ID,
        subRecord: this.subFormField
      });
      this.model.save(dataToSave, () => {
        this.$emit("saveSubformData", ID);
      }).catch(() => {
        store.state.alertMessage = "error";
      });
    }
  }

  async getModelData() {
    this.model.setMainData({
      documentID: this.parentFormData.data.ID,
      subRecord: this.subFormField
    });
    return await this.model.getByID(this.currentID, this.currentModule);
  }

}
